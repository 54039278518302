<script setup>
import {reactive} from "vue";

function back() {
  history.back();
}

const ts = reactive({
  left: 0,
  top: 0,
  x: 0,
  y: 0,
  start: false
})
ts.left = window.innerWidth - 60;
ts.top = window.innerHeight - 100;
function start(event) {
  ts.start = true;
  ts.x = event.touches[0].clientX;
  ts.y = event.touches[0].clientY;
}

function up() {
  ts.start = false;
}

function move(event) {
  if (ts.start) {
    const moveX = event.touches[0].clientX - ts.x;
    const moveY = event.touches[0].clientY - ts.y;
    ts.left += moveX;
    ts.top += moveY;
    ts.x = event.touches[0].clientX;
    ts.y = event.touches[0].clientY;
  }
}
</script>

<template>
  <div @touchstart="start" @touchend="up" @touchmove="move" class="lbh-back"
       :style="{left:ts.left+'px',top:ts.top+'px'}" @click="back">
    返回
  </div>
</template>

<style scoped>
.lbh-back {
  opacity: 0.5;;
  z-index: 9999999999;
  position: fixed;
  width: 2rem;
  height: 2rem;
  background-color: var(--bg-color-yellow-2);
  cursor: default;
  user-select: none;
  color: white;
  border-radius: 2rem;
  font-size: 0.65rem;
  text-align: center;
  line-height: 2rem;
}
</style>
