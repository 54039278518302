import {defineStore} from "pinia";
import {computed, reactive} from "vue";
import {api_company_basic} from "@/api/company";

const sessionId = "session_company_id";
const sessionAccountId = "session_company_account_id";
const companuOver = "session_company_over";
const store_default = ()=>{
    return {
        id:sessionStorage.getItem(sessionId),
        account:sessionStorage.getItem(sessionAccountId),
        over:sessionStorage.getItem(companuOver),
        info:null
    }
}
export const useCompanyStore = defineStore('company',()=>{
    const state = reactive(store_default());
    const id = computed(()=>{
        return  state.id;
    });
    const account = computed(()=>{
        return state.account
    })
    const over = computed(()=>{
        return state.over;
    })
    const info = computed(()=>{
        return ()=>{
            if(!state.info){
                api_company_basic(data=>{
                    state.info = data.data.company;
                });
            }
            return state.info;
        }
    })
    function setter_id(v){
        sessionStorage.setItem(sessionId, v);
        state.id = v;
    }
    function setter_account(v){
        state.account = v;
        sessionStorage.setItem(sessionAccountId,v);
    }
    function action_clean(){
        sessionStorage.removeItem(sessionId);
        sessionStorage.removeItem(sessionAccountId);
        sessionStorage.removeItem(companuOver);
        state.id = null;
        state.account = null;
        state.over = null;
    }
    function action_over(){
        sessionStorage.setItem(companuOver,"1");
        state.over = "1";
    }
    const setter = {
        id:setter_id,
        account:setter_account
    }
    const action = {
        clean:action_clean,
        over:action_over
    }
    return{
        setter,
        action,
        id,
        info,
        account,
        over
    }
})
