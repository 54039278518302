import {directory_salary} from "@/directory/simple/model/salary";
import {directory_sex} from "@/directory/simple/model/sex";
import directorNewInstance from "@/directory/simple/directory";
import {directory_yearsOfWork} from "@/directory/simple/model/yearOfWork";
import {directory_workYear} from "@/directory/simple/model/workYear";
import {directory_salaryRequirement} from "@/directory/simple/model/salaryRequirement";
import {useDirectory} from "@/store/directory_store";
import {directory_interview_status} from "@/directory/simple/model/interviewStatus";
import Dictionary from "@/dictionary/basic";

const define = {
    nature: 1,
    workWay:2,
    education: 3,
    marriage: 4,
    workState:6,
    employeeTotal:7,
    welfare:8,
    bright:9,
    workArea:10,
    information:11
}
export const directory_store_default = ()=>{
    const d = {};
    for(let i in define){
        d[define[i]] = new Dictionary([]);
    }
    return d;
}
function getDirectory(v) {
    return  useDirectory().directory(v);
}
export const global_directory = {
    nature:function(){
        return getDirectory(define.nature);
    },
    workArea:function(){
        return getDirectory(define.workArea);
    },
    education() {
        return getDirectory(define.education);
    },
    marriage() {
        return getDirectory(define.marriage);
    },
    workWay:function(){
        return getDirectory(define.workWay);
    },
    workState:function(){
        return getDirectory(define.workState);
    },
    employeeTotal:function(){
        return getDirectory(define.employeeTotal);
    },
    welfare:function(){
        return getDirectory(define.welfare);
    },
    bright:function(){
        return getDirectory(define.bright);
    },
    information(){
      return getDirectory(define.information);
    },
    salary:directory_salary,
    sex:directory_sex,
    yearsOfWork:directory_yearsOfWork,
    workYear:directory_workYear,
    salaryRequirement:directory_salaryRequirement,
    interviewStatus:directory_interview_status
}
