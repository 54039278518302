class Directory{
    constructor(array){
        this.direcoty = array;
    }
    array(){
        return this.direcoty;
    }
    object(v){
        for(let i = 0 ; i < this.direcoty.length;i++){
            if(this.direcoty[i].value === v){
                return this.direcoty[i];
            }
        }
        return null;
    }
    decode(v){
        let obj = this.object(v);
        if(obj){
            return obj.text;
        }
    }
}
export default function directorNewInstance(array){
    return new Directory(array);
}
