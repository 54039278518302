import directorNewInstance from "@/directory/simple/directory";

let salary;
function create() {
    const arr = [];
    arr.push({text: '面议', value: -1, children: [{text: '面议', value: -1}]});
    let fd = 0;
    let q = 0;
    for (let i = 0; i < 100; i++) {
        const arrr = [];
        if (i < 20) {
            fd = 1000;
        } else if (i < 50) {
            fd = 2000;
        } else if (i < 100) {
            fd = 3000;
        } else if (i < 150) {
            fd = 4000;
        }
        q = q + fd;
        for (let j = 1; j < 8; j++) {
            arrr.push({text: (q + fd * j) + '元', value: (q + fd * j)});
        }
        arr.push(
            {text: q + "元", value: q, children: arrr}
        )
    }
    return arr;
}

export function directory_salary() {
    if (!salary) {
        salary = directorNewInstance(create());
    }
    return salary;
}
