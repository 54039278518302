class Dictionary{
    _object = {};
    _array= [];
    constructor(any,both) {
        if(Array.isArray(any)){
            this._array = [...any];
            if(both){
                this._object = {};
                for(let i = 0 ; i < any.length;i++){
                    this._object[any[i].key] = {value:any[i].value,text:any[i].text};
                }
            }
        }else{
            this._object = {...any};
            if(both){
                this._array = [];
                for(const i in this._object){
                    this._array.push(this._object[i]);
                }
            }
        }
    }
    decode(v){
        let obj = this.find(v);
        if(obj){
            return obj.text;
        }
    }
    find(v){
        if(this._array){
            for(let i = 0 ; i < this._array.length;i++){
                if(this._array[i].value === v){
                    return this._array[i];
                }
            }
        }
    }
    object(){
        return this._object;
    }
    array(){
        return this._array;
    }
}
export default Dictionary;
