import {boot} from "@/network/request";
export function api_person_home_data(success,failed,first) {
    boot({
        url: '/person/home/data',
        method: 'post'
    }, success, failed, first);
}
export function api_person_switch_company(success,failed,first) {
    boot({
        url: '/person/switch/company',
        method: 'post'
    }, success, failed, first);
}

export function api_person_total(success,failed,first) {
    boot({
        url: '/person/total',
        method: 'post'
    }, success, failed, first);
}
export function api_person_basic(success,failed,first) {
    boot({
        url: '/person/basic',
        method: 'post'
    }, success, failed, first);
}
export function api_person_change_state(data,success,failed,first) {
    boot({
        data,
        url: '/person/change/state',
        method: 'post'
    }, success, failed, first);
}
export function api_person_simple_update(data,success,failed,first) {
    boot({
        data,
        url: '/person/simple/update',
        method: 'post'
    }, success, failed, first);
}
