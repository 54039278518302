import directorNewInstance from "@/directory/simple/directory";

let InterviewStatus;
function create(){
    return [
        {value:0,text:'申请中'},
        {value:1,text:'待面试'},
        {value:2,text:'已面试'}
    ]
}
export function directory_interview_status(){
    if(!InterviewStatus){
        InterviewStatus = directorNewInstance(create());
    }
    return  InterviewStatus;
}
