import {showToast} from "vant";
import {quick_person} from "@/quick/person";
import {quick_company} from "@/quick/company";
export function router_person_login(to, form, next) {
    if (quick_person.isLogin()) {
        next();
    } else {
        next({path:'/main/login',query:{type:'1'}});
    }
}
export function router_company_login(to, form, next) {
    if (quick_company.isLogin()) {
        next();
    } else {
        next({path:'/main/login',query:{type:'2'}});
    }
}
export function router_company_over(to,form,next){
    if(quick_company.isOver()){
        next();
    }else{
        showToast("请完成企业信息,绑定企业");
    }
}
export function router_login(to, from, next){
    if(quick_company.isLogin() || quick_person.isLogin()){
        next()
    }else{
        next('/login/select');
    }
}
