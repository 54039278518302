<template>
  <div>
    <router-view></router-view>
    <lbh-back></lbh-back>
  </div>
</template>

<script>


import LbhBack from "@/components/lbh/back/lbh-back.vue";

export default {
  name: 'App',
  components: {
    LbhBack
  }
}
</script>

<style>
#app {
  font-family: Helvetica, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.theme-color-grey{
  background-color: #f6f6f6;
}
.theme-color-white{
  background-color: white;
}
</style>
