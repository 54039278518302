import {boot} from "@/network/request";
export function api_get_directory_position(success,failed){
     boot({
        url:'/directory/get/position/all',
        method:'post'
    },success,failed);
}
export function api_get_directory_area_state(success,failed){
     boot({
        url:'/directory/get/area/state',
        method:'post'
    },success,failed);
}
export function api_directory_get_type(type,success,failed,first){
     boot({
        params:{id:type},
        url:'/directory/get/directory/type',
        method:'post'
    },success,failed,first);
}
