import {defineStore} from "pinia";
import {computed, reactive} from "vue";
import {api_person_basic} from "@/api/person";

const sessionId = "session_person_id";
const sessionPesrsonPercentage = "session_person_percentage"
const store_default = ()=>{
    let percentage = sessionStorage.getItem(sessionPesrsonPercentage);
    percentage = percentage|0;
    return {
        id:sessionStorage.getItem(sessionId),
        percentage:percentage,
        info:{},
        infoInit:false
    }
}
export const usePersonStore = defineStore('person',()=>{
    const state = reactive(store_default());
    const id = computed(()=>{
        return  state.id;
    });
    const percentage = computed(()=>{
        return state.percentage;
    })
    const info = computed(()=>{
       return ()=>{
           if(!state.infoInit){
               api_person_basic(data=>{
                   state.info = data.data.person;
                   state.infoInit = true
               })
           }
           return state.info;
       }
    });
    const setter_id =(v)=> {
        sessionStorage.setItem(sessionId, v);
        state.id = v;
    }
    const setter_percentage = (v)=>{
        sessionStorage.setItem(sessionPesrsonPercentage, v);
        state.percentage = v;
    }
    function action_clean(){
        sessionStorage.removeItem(sessionId);
        state.id = null;
    }
    function refresh(){
        api_person_basic(data=>{
            state.info = data.data.person;
            state.infoInit = true
        })
    }
    const setter = {
        id:setter_id,
        percentage:setter_percentage
    }
    const action = {
        clean:action_clean,
        refresh:refresh
    }
    return{
        setter,
        action,
        id,
        info,
        percentage
    }
})
