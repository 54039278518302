import directorNewInstance from "@/directory/simple/directory";

let sex ;
function create(){
    return [{value:1,text:'男'},{value:2,text:'女'}];
}
export function directory_sex(){
    if(!sex){
        sex = directorNewInstance(create());
    }
    return sex;
}
