import {defineStore} from "pinia";
import {computed, reactive} from "vue";
import {directory_store_default} from "@/directory/global_directory";
import {api_directory_get_type} from "@/api/directory";
import Dictionary from "@/dictionary/basic";

export const useDirectory = defineStore('directory',()=>{
    const state = reactive({
        directory: directory_store_default()
    })
    const load = {};
    const directory = computed(()=>{
        return (v)=>{
            if(state.directory[v].array().length < 1){
                if(!load[v]){
                    load[v] = true;
                    api_directory_get_type(v,data=>{
                        state.directory[v] = new Dictionary(JSON.parse(data.data.directory));
                        load[v] = false;
                    },()=> {
                        load[v] = false;
                    })
                }
            }
            return state.directory[v];
        }
    });
    return {
        directory
    }
});
