import {baseUrl} from "@/network/config";
const base_url = baseUrl+"/source/";
export const api_source_person_default=(p)=>{
    return base_url+"default?r=person&p="+p;
}
export const api_source_company_default=(p)=>{
    return base_url+"default?r=company&p="+p;
}
export const api_source_person_head=(v)=>{
    return base_url+"person" +"?p=head&v="+v;
}
export const api_source_company_logo=(v)=>{
    return base_url +"company?p=logo&v="+v;
}
export const api_source_company_visualize=(v)=>{
    return base_url +"company?p=visualize&v="+v;
}
export const api_source_company_account_job_prov=(v)=>{
    return base_url +"company_account?p=job_prov&v="+v;
}
export const api_source_company_account_business=(v)=>{
    return base_url +"company_account?p=business&v="+v;
}
export const api_source_company_business=(v)=>{
    return base_url +"company?p=business&v="+v;
}
export const api_job_fair_title_image=(v)=>{
    return base_url +"job/fair?p=title&v="+v;
}
export const api_job_fair_hall_top_image=(v)=>{
    return base_url +"job/fair?p=top&v="+v;
}

