import directorNewInstance from "@/directory/simple/directory";

let yearsOfWork ;
function create(){
    let arr = [];
    for(let i = 0 ; i < 100;i++){
        arr.push({value:i,text:i+'年'});
    }
    return arr;
}
export function directory_yearsOfWork(){
    if(!yearsOfWork){
        yearsOfWork = directorNewInstance(create());
    }
    return yearsOfWork;
}
