import directorNewInstance from "@/directory/simple/directory";

let workYear;
function create() {
    const options = [
        {text:'不限',value:-1,real:[-1,99]},
        {text:'无工作经验',value:0,real:[0,0]},
        {text:'1年',value:1,real:[1,1]},
        {text:'2-5年',value:2,real: [2,5]},
        {text:'5年及以上',value:5,real: [5,99]}
    ]
    return options;
}

export function directory_workYear() {
    if (!workYear) {
        workYear = directorNewInstance(create());
    }
    return workYear;
}
