import {api_source_company_default, api_source_company_logo} from "@/api/source";
import {global_directory} from "@/directory/global_directory";
import {useCompanyStore} from "@/store/comany_store";
import {quick_person} from "@/quick/person";
export const quick_company = {
    logo:function(v){
        if(v){
            return api_source_company_logo(v);
        }
        return api_source_company_default('logo');
    },
    logout:function(){
        useCompanyStore().action.clean();
    },
    login:function(account,id,over){
        quick_person.logout();
        useCompanyStore().action.clean();
        useCompanyStore().setter.id(id);
        useCompanyStore().setter.account(account);
        quick_company.over(over)
    },
    education(v) {
        if( v === -1){
            return '不限';
        }
        if(v==null){
            return null;
        }
        return global_directory.education().decode(v);
    },
    over:function(v){
        if(v){
            useCompanyStore().action.over();
        }
    },
    isOver(){
        return useCompanyStore().over == "1";
    },
    isLogin(){
        return useCompanyStore().account != null;
    }
}
