import axios from "axios";
import {baseUrl} from "@/network/config.js"
import router from "@/router/router";
import {quick} from "@/quick/quick";
import {quick_person} from "@/quick/person";
import {quick_company} from "@/quick/company";
const service = axios.create({
    baseURL: baseUrl,
    timeout: 20000000
});
service.interceptors.request.use(
    config => {
        return config;
    }, error => {
        return Promise.reject(error);
    }
);
service.interceptors.response.use(config => {
    return config;
}, error => {
    if (error.response.status === 302) {
        quick.logout();
        router.push("/main/index");
    }
});

export const boot = (config, success, failed, first) => {
    return service(config)
        .then((data) => {
            if (first) {
                first();
            }
            if(!data){
                return;
            }
            if (data.data.success) {
                success(data.data);
            } else {
                if(failed){
                    failed(data.data);
                }
            }
        }).finally(() => {
        if (first) {
            first();
        }
    });
}
export const boot_person_login = (config,success,failed,first)=>{
    if(!quick_person.isLogin()){
        router.push("/main/login/person");
    }else{
        boot(config, success, failed, first);
    }
}
export const boot_company_login = (config,success,failed,first)=>{
    if(!quick_company.isLogin()){
        router.push("/main/login/company");
    }else{
        boot(config, success, failed, first);
    }
}
export default service;
