import Dictionary from "@/dictionary/basic";

let salaryRequirement ;
function create(){
    return [
        {value:1,text:'1000-3000',real:[1000,3000]},
        {value:2,text:'3000-6000',real:[3000,6000]},
        {value:3,text:'6000-9000',real:[6000,9000]},
        {value:4,text:'9000以上',real: [9000,null]},
        {value:5,text:'面议',real: [-1,null]}
    ];
}
export function directory_salaryRequirement(){
    if(!salaryRequirement){
        salaryRequirement = new Dictionary(create());
    }
    return salaryRequirement;
}
