import _95date from "@/util/_95/Date";
import {usePersonStore} from "@/store/person_store";
import {useCompanyStore} from "@/store/comany_store";
import {useLoginStore} from "@/store/login_store";
export const quick = {
    salary_simple(min,max){
        if(min == null){
            return null;
        }
        function k(v){
            return Math.floor(v*10/1000,2)/10;
        }
        if(min === 0 || min == null){
            return null;
        }
        if(min === -1){
            return '面议';
        }
        if(max === 0 || max == null){
            return k(min) +'K以上';
        }
        return k(min)+'K' + ' - ' + k(max)+'K';
    },
    month_day(d){
        const arr = _95date.stringGetArray(d);
        return arr[1]+'-'+arr[2];
    },logout(){
        useCompanyStore().action.clean();
        useLoginStore().action.clean();
        usePersonStore().action.clean();
        localStorage.clear();
        sessionStorage.clear();
    },isLogin(){
        return usePersonStore().id !=null || useCompanyStore().account != null;
    }
}
