import {defineStore} from "pinia";
import {computed, reactive} from "vue";
const login_type_as = "login_type_2024";
const client_id_as = "client_id_2024";
export const useLoginStore = defineStore("useLoginStore",()=>{
    const state = reactive(()=>{
        return {
            login_type:sessionStorage.getItem(login_type_as),
            client_id:sessionStorage.getItem( client_id_as)
        }
    });
    const login_type = computed(()=>{
        return state.login_type;
    })
    const client_id = computed(()=>{
        return state.client_id;
    })
    const action= {
        clean(){
            state.login_type = null;
            state.client_id = null;
        }
    }
    const setter = {
        login_type(login_type){
            sessionStorage.setItem(login_type_as,login_type);
            state.login_type = login_type;
        },
        client_id(client_id){
            sessionStorage.setItem(client_id_as,client_id)
            state.client_id = client_id;
        }
    }
    return{
        login_type,client_id,setter,action
    }
});
