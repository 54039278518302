import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/font/iconfont.css'
import {Button, Popup, Toast, Loading,ImagePreview  ,Empty , Swipe, SwipeItem, Switch ,DatePicker, Cascader, Picker, Search,Dialog,Skeleton,Checkbox} from "vant";
import 'vant/lib/index.css';
import routes from '@/router/router';
import {createPinia} from "pinia";
import instructBuilder from "@/instruct";
const pinia = createPinia();
 const app = createApp(App)
    .use(pinia)
    .use(Button)
    .use(Popup)
    .use(Toast)
     .use(Switch)
    .use(Loading)
    .use(Picker)
     .use(ImagePreview)
     .use(Swipe)
     .use(SwipeItem)
    .use(Cascader)
    .use(DatePicker)
     // .use(Popover)
    .use(Search)
    .use(Dialog)
    .use(Skeleton)
     .use(Empty)
    .use(Checkbox).use(routes).mount('#app');
instructBuilder(app);
