import {api_source_person_head} from "@/api/source";
import {usePersonStore} from "@/store/person_store";
import {quick_company} from "@/quick/company";
export const quick_person = {
    head:function(v){
        if(v){
            return api_source_person_head(v);
        }else{
            if(usePersonStore().info().sex === 2){
                return "/static/image/default/female.png"
            }else{
                return "/static/image/default/male.png"
            }
        }
    },
    logout:function(){
        usePersonStore().action.clean();
    },
    login(v,p){
        quick_company.logout();
        usePersonStore().setter.id(v);
        if(p){
            usePersonStore().setter.percentage(p);
        }
    },
    percentage(){
      return usePersonStore().percentage > 60;
    },
    isLogin(){
        return usePersonStore().id != null;
    },
    concealName(name,sex){
        if(name){
            return name.substring(0,1)+((sex === 2)?"女士":"先生");
        }
        return null;
    }
}

