import {createRouter, createWebHashHistory} from 'vue-router';
import {router_company_login, router_company_over, router_person_login} from "@/router/router-login";

const routes = [
    {path: '/login/select', component: () => import('@/view/login/Login-Select.vue')},
    {
        path: '/main', component: () => import('@/view/main/Nav-Footer-Index.vue'),
        children: [
            {path: 'index', component: () => import("@/view/index/Index.vue")},
            {path: 'login', component: () => import('@/view/login/Login-Index.vue')},
            {path: 'login/person', component: () => import('@/view/login/Login-Index.vue'),meta:{type:'1'}},
            {path: 'login/company', component: () => import('@/view/login/Login-Index.vue'),query:{type:'2'}},
            {path: 'person/home', component: () => import('@/view/person/home/Person-Home-Index.vue'),beforeEnter:router_person_login},
            {path: 'company/home', component: () => import('@/view/company/home/Company-Home-Index.vue'),beforeEnter:router_company_login},
            {path: 'talents/find' ,  component: () => import('@/view/talents/find/Talents-Find-Index.vue')},
            {path: 'jobs/find', component: () => import('@/view/jobs/find/Jobs-Find-Index.vue')},
            {path:'company/message/list', component:()=>import('@/view/company/message/list/message-list-index.vue'),beforeEnter:router_company_login},
            {path: 'person/message/list',component:()=>import('@/view/person/message/list/message-list-index.vue'),beforeEnter:router_person_login},
            {path:'company/resume',component:()=>import('@/view/company/resume/company-resume-index.vue'),beforeEnter:router_company_over},
            {path:'information/index',component:()=>import('@/view/information/information-index.vue')},
            {path:'information/detail',component:()=>import('@/view/information/information-detail.vue')},
            {path:'fair/index',component:()=>import('@/view/fair/fair-index.vue')},
            {path:'company/job/fair/list',component:()=>import('@/view/company/fair/list/company-job-fair-list-index.vue'),beforeEnter:router_company_login},
            {path:'special',component:()=>import('@/view/special/special-index.vue'),
            children:[
                {path:'position',component:()=>import('@/view/special/position/special-position-list.vue')}
            ]}
        ]

    },
    {path: '/person/register', component: () => import('@/view/person/register/register-index.vue')},
    {path: '/person/resume-manage', component: () => import('@/view/person/resume-manage/Person-Resume-Manage-Index.vue'),beforeEnter:router_person_login},
    {path: '/person/resume-manage/basic', component: () => import('@/view/person/resume-manage/view/basic/Person-Resume-Manage-Basic-Index.vue'),beforeEnter:router_person_login},
    {path: '/person/resume-manage/intention', component: () => import('@/view/person/resume-manage/view/intention/Person-Resume-Manage-Intention-Index.vue'),beforeEnter:router_person_login},
    {path: '/person/resume-manage/company', component: () => import('@/view/person/resume-manage/view/company/Person-Resume-Manage-Company-Index.vue'),beforeEnter:router_person_login},
    {path: '/person/resume-manage/education', component: () => import('@/view/person/resume-manage/view/education/Person-Resume-Manage-Education-Index.vue'),beforeEnter:router_person_login},
    {path: '/person/resume-detail/index', component: () => import('@/view/person/resume-detail/person-resume-detail-index.vue'),beforeEnter:router_person_login},
    {path: '/person/message/chat',component:()=>import('@/view/person/message/chat/message-chat-index.vue'),beforeEnter:router_person_login},
    {path: '/person/company', component: () => import('@/view/person/company/person-company-index.vue'),beforeEnter:router_person_login},
    {
        path: '/company/register',component:()=>import('@/view/company/register/Company-Register-Index.vue'),
        children: [
            {path:'info',component:()=>import("@/view/company/register/Company-Register-Info.vue")},
            {path:'image',component:()=>import("@/view/company/register/Company-Register-Image.vue")},
            {path:'contact',component:()=>import("@/view/company/register/Company-Register-Contact.vue")}
        ]
    },
    {path: '/company/manage',component:()=>import('@/view/company/manage/Company-Manage-Index.vue'),beforeEnter:router_company_login},
    {path:'/company/position/release',component:()=>import('@/view/company/position-release/Position-Release-Index.vue'),beforeEnter:router_company_over},
    {path:'/company/position/manage',component:()=>import('@/view/company/position-manage/Position-Manage-Index.vue'),beforeEnter:router_company_over},
    {path:'/company/position/detail',component:()=>import('@/view/public/company/position/preview/company-position-preview-Index.vue'),beforeEnter:router_company_login},
    {path:'/company/resume/receive',component:()=>import('@/view/company/resume/receive/company-resume-receive-index.vue'),beforeEnter:router_company_login},
    {path:'/company/resume/collect',component:()=>import('@/view/company/resume/collect/company-resume-collect-index.vue'),beforeEnter:router_company_login},
    {path:'/company/resume/detail', component:()=>import('@/view/company/resume/company-resume-detail.vue'),beforeEnter:router_company_login},
    {path:'/company/resume/browse-position/me', component:()=>import('@/view/company/resume/browse-position/company-resume-browse-position-index.vue'),beforeEnter:router_company_login},
    {path:'/company/resume/browse-resume', component:()=>import('@/view/company/resume/browse-resume/company-resume-browse-resume-index.vue'),beforeEnter:router_company_login},
    {path:'/company/message/chat', component:()=>import('@/view/company/message/chat/message-chat-index.vue'),beforeEnter:router_company_over},
    {path:'/talent/resume',component:()=>import('@/view/talents/resume/talent-resume-index.vue')},
    {path:'/jobs/position',component:()=>import('@/view/jobs/position/job-position-index.vue')},
    {path:'/jobs/company',component:()=>import('@/view/jobs/company/job-company-index.vue')},
    {path:'/fair/hall',component:()=>import('@/view/fair/hall/fair-hall-index.vue')}

];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;
