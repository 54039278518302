function _95Date() {
  this.version = '1.3'
  this.updateTime = '2022-10-18'
  this.author = '95'
  /**
   * 字符串转时间
   * @param str
   * @returns {Date}
   */
  this.stringParseTime = function(str) {
    var ary = str.split(' ')
    var dry = ary[0].split('-')
    var d = new Date(dry[0], dry[1] - 1, dry[2])
    if (ary.length > 1) {
      var tmr = ary[1].split(':')
      d.setHours(Number(tmr[0]))
      d.setMinutes(Number(tmr[1]))
      if (tmr[2].indexOf('.') > 1) {
        var mil = tmr[2].split('.')
        d.setSeconds(Number(mil[0]))
        d.setMilliseconds(Number(mil[1]))
      } else {
        d.setSeconds(Number(tmr[2]))
        d.setMilliseconds(0)
      }
    }
    return d
  }
  /**
   *增加日期
   *参数为负是减少
   * @param date
   * @param y year
   * @param m month
   * @param d date
   * @returns {Date}
   */
  this.datePlus = function(date, y, m, d) {
    var yy = date.getFullYear()
    var mm = date.getMonth()
    var dd = date.getDate()
    yy += y; mm += m; dd += d
    var da = new Date(yy, mm, dd)
    return da
  }
  /**
   * 增加时间
   * 参数为负是减少
   * @param date
   * @param h hours
   * @param m minutes
   * @param s seconds
   * @returns {Date}
   */
  this.timePlus = function(date, h, m, s) {
    var hh = date.getHours()
    var mm = date.getMinutes()
    var ss = date.getSeconds()
    hh += h; mm += m; ss += s
    var da = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hh, mm, ss)
    return da
  }

  /**
   * 总时间转字符串 2022-01-01 00:00:00
   * @param date
   * @returns {string}
   */
  this.dateTimeParseString = function(date) {
    function fomat(str) {
      str = String(str)
      if (str.length < 2) {
        str = '0' + str
      }
      return str
    }
    return date.getFullYear() + '-' + fomat(date.getMonth() + 1) + '-' + fomat(date.getDate()) + ' ' +
			fomat(date.getHours()) + ':' + fomat(date.getMinutes()) + ':' + fomat(date.getSeconds())
  }

  /**
   * 时间转字符串 00:00:00
   * @param date
   * @returns {string}
   */
  this.timeParseString = function(date) {
    function fomat(str) {
      str = String(str)
      if (str.length < 2) {
        str = '0' + str
      }
      return str
    }
    return fomat(date.getHours()) + ':' + fomat(date.getMinutes()) + ':' + fomat(date.getSeconds())
  }
  /**
   * 日期转字符串 2000-01-01
   * @param date
   * @returns {string}
   */
  this.dateParseString = function(date) {
    function fomat(str) {
      str = String(str)
      if (str.length < 2) {
        str = '0' + str
      }
      return str
    }
    return date.getFullYear() + '-' + fomat(date.getMonth() + 1) + '-' + fomat(date.getDate())
  }

  /**
   * 获取指定时间的周一
   * @param date
   * @returns {Date}
   */
  this.getWeekMonDay = function(date) {
    var days = date.getDay()
    days = days === 0 ? 7 : days
    const weekFirstDay = new Date(date - (days - 1) * 86400000)
    let firstMonth = Number(weekFirstDay.getMonth()) + 1
    if (firstMonth < 10) {
      firstMonth = '0' + firstMonth
    }
    let weekFirstDays = weekFirstDay.getDate()
    if (weekFirstDays < 10) {
      weekFirstDays = '0' + weekFirstDays
    }
    return this.stringParseTime(weekFirstDay.getFullYear() + '-' + firstMonth + '-' + weekFirstDays)
  }

  /**
   * 获取指定时间的周末
   * @param date
   * @returns {Date}
   */
  this.getWeekSunDay = function(date) {
    var days = date.getDay()
    days = days === 0 ? 7 : days
    const weekFirstDay = new Date(date - (days - 1) * 86400000)
    const weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000)
    let lastMonth = Number(weekLastDay.getMonth()) + 1
    if (lastMonth < 10) {
      lastMonth = '0' + lastMonth
    }
    let weekLastDays = weekLastDay.getDate()
    if (weekLastDays < 10) {
      weekLastDays = '0' + weekLastDays
    }
    return this.stringParseTime(weekLastDay.getFullYear() + '-' + lastMonth + '-' + weekLastDays)
  }
  this.betweenToYear = function(date1,date2){
    const t = date1 - date2;
    return Math.floor(t/1000/60/60/24/365);
  }
  this.betweenToDay = function (date1,date2){
    const t = date1- date2;
    return Math.floor(t/1000/60/60/24);
  }

  this.stringGetArray = function (data){
    if(!data){
      return [];
    }
    let ds = data.split(' ');
    let dd = ds[0].split('-');
    function fomat(str) {
      str = String(str)
      if (str.length < 2) {
        str = '0' + str
      }
      return str
    }
    const time = [];
    time.push(dd[0]);
    time.push(fomat(dd[1]));
    time.push(fomat(dd[2]));
    if(ds.length > 1){
      let  dt = ds[1].split(':');
      time.push(fomat(dt[0]));
      time.push(fomat(dt[1]));
      time.push(fomat(dt[2]));
    }
    return time;
  }
  this.getDate = function(date,f){
    const arr = this.stringGetArray(date)
    return arr[0] + f + arr[1] + f+ arr[2];
  }
}

const _95date = new _95Date()
export default _95date
