import {boot} from "@/network/request";
export function api_company_home(success,failed,first){
    boot({
        url:'/company/home',
        method:'post'
    },success,failed,first);
}
export function api_company_switch_person(success,failed,first){
    boot({
        url:'/company/switch/person',
        method:'post'
    },success,failed,first);
}
export function api_company_register(data,success,failed){
    boot({
        url:'/company/register',
        method:'post',
        data,
        headers:[{'Content-Type':'multipart/form-data'}]
    },success,failed)
}
export function api_company_register_two_update(data,success,failed){
    boot({
        url:'/company/register/two/update',
        method:'post',
        data,
        headers:[{'Content-Type':'multipart/form-data'}]
    },success,failed)
}
export function api_company_manage_get(success,failed){
    boot({
        url:'/company/manage/get',
        method:'post'
    },success,failed)
}
export function api_company_datum_info_update(data,success,failed){
    boot({
        url:'/company/manage/datum/info/update',
        method:'post',
        data
    },success,failed)
}
export function api_company_manage_logo_update(data,success,failed){
    boot({
        url:'/company/manage/logo/upload',
        method:'post',
        data,
        headers:[{'Content-Type':'multipart/form-data'}]
    },success,failed)
}
export function api_company_bright_update(data,success,failed){
    boot({
        url:'/company/manage/bright/update',
        method:'post',
        data
    },success,failed)
}
export function api_company_manage_Authentication_update(data,success,failed){
    boot({
        url:'/company/manage/authentication/update',
        method:'post',
        data,
        headers:[{'Content-Type':'multipart/form-data'}]
    },success,failed)
}
export function api_company_manage_visualize_update(data,success,failed){
    boot({
        url:'/company/manage/visualize/update',
        method:'post',
        data,
        headers:[{'Content-Type':'multipart/form-data'}]
    },success,failed)
}
export function api_company_home_total(success,failed){
    boot({
        url:'/company/home/total',
        method:'post',
    },success,failed)
}
export function api_company_basic(success){
    boot({
        url:'/company/basic',
        method:'post',
    },success)
}
export function api_company_register_check(data,success,failed){
    boot({
        url:'/company/register/check',
        method:'post',
        data
    },success,failed)
}
export function api_company_bind(data,success,failed){
    boot({
        url:'/company/bind',
        method:'post',
        data
    },success,failed)
}
